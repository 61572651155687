import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceAgreementAddModel } from '../shared/models/service-agreement.model';
import { AppConstants } from '../utility/app-constants';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ServiceAgreementResponse, ServiceAgreementResponseProfile, UserGroupResponse, ServiceAgreementProfileResponse } from '../shared/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceAgreementService {

  baseUrl:String;

  constructor(
    private http:HttpClient,
  ) { 
    this.baseUrl = AppConstants.getBaseURL();
  }

  public handleError(error) {
    return throwError(error);
  }

  public addServiceAgreement(newsFeedModel:ServiceAgreementAddModel, companyCode){
    
    return this.http.post<ServiceAgreementResponseProfile>(this.baseUrl +'/FDSServiceAgreement',newsFeedModel).pipe(
      catchError(this.handleError)
      );
  }

  updateServiceAgreement(fdsServiceAgreementUpdateRequestData, id: string){
    const params = new HttpParams().set('id', id);
    return this.http.put<ServiceAgreementResponseProfile>(this.baseUrl +'/FDSServiceAgreement',fdsServiceAgreementUpdateRequestData, {params}).pipe(
      catchError(this.handleError)
      );
  }
  

  editServiceAgreement(fdsServiceAgreementUpdateRequestData, id: string){
    const params = new HttpParams().set('id', id);
    return this.http.put<ServiceAgreementResponseProfile>(this.baseUrl +'/FDSServiceAgreement/UpdateEnvelop',fdsServiceAgreementUpdateRequestData, {params}).pipe(
      catchError(this.handleError)
      );
  }

  public getRecipientsDetails(fdsId){
    const params = new HttpParams().set('fdsId', fdsId);

    return this.http.get(this.baseUrl +'/FDSServiceAgreement/GetRecipientsDetails',{params}).pipe(
      catchError(this.handleError)
      );
  }

  public GetSetLockInfo(id: any){
    const params = new HttpParams().set('fdsId', id);

    return this.http.get(this.baseUrl +'/FDSServiceAgreement/GetSetLockInfo',{params}).pipe(
      catchError(this.handleError)
      );
  }
  
  
  public DeleteLock(id: any,lockedByApp: any,lockToken: any){
    const params = new HttpParams().set('fdsId', id).set('lockedByApp', lockedByApp).set('lockToken', lockToken);

    return this.http.get(this.baseUrl +'/FDSServiceAgreement/DeleteLock',{params}).pipe(
      catchError(this.handleError)
      );
  }

  public GetEnvelopeCorrectViewByFdsId(id: any){
    const params = new HttpParams().set('fdsId', id);

    return this.http.get(this.baseUrl +'/FDSServiceAgreement/GetEnvelopeCorrectViewByFdsId',{params}).pipe(
      catchError(this.handleError)
      );
  }

  public getEnvelopEditUrl(fdsId:any, envilopid){
    const params = new HttpParams().set('fdsId', fdsId).set('envilopid', envilopid);

    return this.http.get(this.baseUrl +'/FDSServiceAgreement/getEnvelopEditUrl',{params}).pipe(
      catchError(this.handleError)
      );
  }

  public serviceAgreementList(skip, take) {
    const params = new HttpParams().set('skip', skip).set('take', take);
    return this.http.get<ServiceAgreementResponse>(this.baseUrl+'/FDSServiceAgreements', { params } );
  }

  public deleteServiceAgreemen(id:string){
    const params = new HttpParams().set('id', id);
    return this.http.delete<any>(this.baseUrl +'/FDSServiceAgreement?id='+id+"&isArchived=true").pipe(
      catchError(this.handleError)
      );
  }

  public voideServiceAgreemen(id:string){
    const params = new HttpParams().set('id', id);
    return this.http.delete<any>(this.baseUrl +'/FDSServiceAgreement/VoidEnvelope', {params}).pipe(
      catchError(this.handleError)
      );
  }
  
  public getUserGroupList(xeppoGroupCode, companyCode){
    const params = new HttpParams().set('XeppoGroupCode', xeppoGroupCode).set('companyCode', companyCode);
    return this.http.get<UserGroupResponse>(this.baseUrl+'/FDSServiceAgreement/GetUserGroup',{params});
  }

  public setFDStatus(id:string,ststus) {
    const params = new HttpParams().set('fDSAgreementStatus', ststus).set('id', id);
    return this.http.put<any>(this.baseUrl+'/FDSServiceAgreement/SetFDStatus',{},{params}).pipe(
      catchError(this.handleError)
    );
  }

  public serviceAgreementByXeppoCodeClientCodeList(xeppoCode, clientCode) {
    const params = new HttpParams().set('xeppoCode', xeppoCode).set('clientCode', clientCode).set('isMobile', 'false');
    return this.http.get<ServiceAgreementProfileResponse>(this.baseUrl+'/FDSServiceAgreement/GetByXeppoCodeClientCode', { params } );
  }

  public getDSToken() {
    // const params = new HttpParams().set('xeppoCode', xeppoCode).set('clientCode', clientCode);
    return this.http.get<any>(this.baseUrl+'/DS/DSToken' );
  }

  public getDSGetTemplates(accessToken) {
    const params = new HttpParams().set('accessToken', accessToken);
    return this.http.get<any>(this.baseUrl+'/DS/DSGetTemplates', { params } );
  }

  public editDocusign(){
    
    return this.http.post<any>('https://demo.docusign.net/restapi/v2.1/accounts/de5d43fe-2aba-40ae-bc55-9701e0fb4815/envelopes/1aa55b53-e8d4-46ef-9beb-d863daac3e05/views/edit','').pipe(
      catchError(this.handleError)
      );
  }

}
