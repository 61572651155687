export class AppConstants {   

    // public static getLogoURL():string{return 'assets/img/logo.png'}
    // public static getLogoNameURL():string{return 'assets/img/logo_name.png'}
    // public static getBusinessName():string{return 'Fortress'}

    // // // local

    // public static getBaseURL():String{return 'https://api.client.fortress.xigenix.com/api'}
    // public static getAuthURL():String{return 'https://api.staffauth.fortress.xigenix.com/api'}
    // public static getStaffPortalURL():string{return 'http://localhost:4201'}
    // public static getAuthDomain():string{return 'localhost'}
    // public static getStaffApiURL():String{return 'https://api.staff.fortress.xigenix.com/api'}
    // public static getFactFinderURL():string{return 'http://localhost:4205'}
    // public static getFamilyTreeURL():string{return 'http://localhost:4202'}
    // public static getFamilyTreeApiURL():string{return 'https://api.facfamilytree.fortress.xigenix.com/api'}
    // public static getClientAuthURL():String{return 'https://api.clientauth.fortress.xigenix.com/api'}
    // // public static getNetwortHistoryURL():string{return 'https://api.networth.history.fortress.xigenix.com/api'}
    // public static getNetwortHistoryURL():string{return 'https://api.networthhistory.myfortress.com.au/api'}
    // public static getMoneysoftURL():string{return 'http://localhost:4206'}
    // public static getXeppoBaseUrl():string{return 'https://api.xeppo.com.au'}

    // // // test
    // public static getBaseURL():String{return 'https://api.client.fortress.xigenix.com/api'}
    // public static getAuthURL():String{return 'https://api.staffauth.fortress.xigenix.com/api'}
    // public static getStaffPortalURL():string{return 'https://staffportal.fortress.xigenix.com'}
    // public static getAuthDomain():string{return '.fortress.xigenix.com'}
    // public static getStaffApiURL():String{return 'https://api.staff.fortress.xigenix.com/api'}
    // public static getFactFinderURL():string{return 'https://factfinder.fortress.xigenix.com'}
    // public static getFamilyTreeURL():string{return 'https://familytree.fortress.xigenix.com'}
    // public static getFamilyTreeApiURL():string{return 'https://api.facfamilytree.fortress.xigenix.com/api'}
    // public static getClientAuthURL():String{return 'https://api.clientauth.fortress.xigenix.com/api'}
    // // public static getNetwortHistoryURL():string{return 'https://api.networth.history.fortress.xigenix.com/api'}
    // public static getNetwortHistoryURL():string{return 'https://api.networthhistory.myfortress.com.au/api'}
    // public static getMoneysoftURL():string{return 'https://moneysoft.fortress.xigenix.com'}
    // public static getXeppoBaseUrl():string{return 'https://api.xeppo.com.au'}

    //live
    // public static getBaseURL():String{return 'https://api.myfortress.com.au/api'}
    // public static getAuthURL():String{return 'https://api.auth.myfortress.com.au/api'}
    // public static getStaffPortalURL():string{return 'https://staffportal.myfortress.com.au'}
    // public static getAuthDomain():string{return '.myfortress.com.au'}
    // public static getStaffApiURL():String{return 'https://api.staff.myfortress.com.au/api'}
    // public static getFactFinderURL():string{return 'https://facfinder.myfortress.com.au'}
    // public static getFamilyTreeURL():string{return 'https://familytree.myfortress.com.au'}
    // public static getFamilyTreeApiURL():string{return 'https://api.facfamilytree.myfortress.com.au/api'}
    // public static getClientAuthURL():String{return 'https://api.clientauth.myfortress.com.au/api'}




    // ===== Entity 1 client portal ===========================================================================================

    // public static getLogoURL():string{return 'assets/img/logo_entity1.png'}
    // public static getLogoNameURL():string{return 'assets/img/logo_name_entity1.png'}
    // public static getBusinessName():string{return 'Business 2'}

    // local entity 1
    // public static getBaseURL():String{return 'https://test.api.entity1.clientportal.3play.com.au/api'}
    // public static getAuthURL():String{return 'https://test.api.staffportalauth.3play.com.au/api'}
    // public static getStaffPortalURL():string{return 'http://localhost:4201'}
    // public static getAuthDomain():string{return 'localhost'}
    // public static getStaffApiURL():String{return 'https://test.api.entity1.staffportal.3play.com.au/api'}
    // public static getFactFinderURL():string{return 'http://localhost:4205'}
    // public static getFamilyTreeURL():string{return 'http://localhost:4202'}
    // public static getFamilyTreeApiURL():string{return 'https://test.api.entity1.facfamilytree.3play.com.au/api'}
    // public static getClientAuthURL():String{return 'https://test.api.clientauth.3play.com.au/api'}

    // test entity 1
    // public static getBaseURL():String{return 'https://test.api.entity1.clientportal.3play.com.au/api'}
    // public static getAuthURL():String{return 'https://test.api.staffportalauth.3play.com.au/api'}
    // public static getStaffPortalURL():string{return 'https://test.staffportal.entity1.3play.com.au'}
    // public static getAuthDomain():string{return '.3play.com.au'}
    // public static getStaffApiURL():String{return 'https://test.api.entity1.staffportal.3play.com.au/api'}
    // public static getFactFinderURL():string{return 'https://test.factfinder.entity1.3play.com.au'}
    // public static getFamilyTreeURL():string{return 'https://test.familytree.entity1.3play.com.au'}
    // public static getFamilyTreeApiURL():string{return 'https://test.api.entity1.facfamilytree.3play.com.au/api'}
    // public static getClientAuthURL():String{return 'https://test.api.clientauth.3play.com.au/api'}




    // ===== Milton client portal ===========================================================================================

    public static getLogoURL():string{return 'assets/img/logo.png'}
    public static getLogoNameURL():string{return 'assets/img/logo_name.png'}
    public static getBusinessName():string{return 'My Fortress Milton'}

   // live
    public static getBaseURL():String{return 'https://api.milton.myfortress.com.au/api'}
    public static getAuthURL():String{return 'https://api.auth.myfortress.com.au/api'}
    public static getStaffPortalURL():string{return 'https://staffportal.milton.myfortress.com.au'}
    public static getAuthDomain():string{return '.myfortress.com.au'}
    public static getStaffApiURL():String{return 'https://api.milton.staff.myfortress.com.au/api'}
    public static getFactFinderURL():string{return 'https://facfinder.milton.myfortress.com.au'}
    public static getFamilyTreeURL():string{return 'https://familytree.milton.myfortress.com.au'}
    public static getFamilyTreeApiURL():string{return 'https://api.milton.facfamilytree.myfortress.com.au/api'}
    public static getClientAuthURL():String{return 'https://api.clientauth.myfortress.com.au/api'}
    public static getNetwortHistoryURL():string{return 'https://api.networthhistory.myfortress.com.au/api'}
    public static getMoneysoftURL():string{return 'https://moneysoft.milton.myfortress.com.au'}




    // ===== Townsville client portal ===========================================================================================

    // public static getLogoURL():string{return 'assets/img/logo.png'}
    // public static getLogoNameURL():string{return 'assets/img/logo_name.png'}
    // public static getBusinessName():string{return 'My Fortress'}

    // live Environment
    // public static getBaseURL():String{return 'https://api.townsville.myfortress.com.au/api'}
    // public static getAuthURL():String{return 'https://api.auth.myfortress.com.au/api'}
    // public static getStaffPortalURL():string{return 'https://staffportal.townsville.myfortress.com.au'}
    // public static getAuthDomain():string{return '.myfortress.com.au'}
    // public static getStaffApiURL():String{return 'https://api.townsville.staff.myfortress.com.au/api'}
    // public static getFactFinderURL():string{return 'https://facfinder.townsville.myfortress.com.au'}
    // public static getFamilyTreeURL():string{return 'https://familytree.townsville.myfortress.com.au'}
    // public static getFamilyTreeApiURL():String{return 'https://api.townsville.facfamilytree.myfortress.com.au/api'}
    // public static getClientAuthURL():String{return 'https://api.clientauth.myfortress.com.au/api'}
    // public static getNetwortHistoryURL():string{return 'https://api.networthhistory.myfortress.com.au/api'}
    // public static getMoneysoftURL():string{return 'https://moneysoft.townsville.myfortress.com.au'}

    //UAT Environment
    // public static getBaseURL():String{return 'https://clientportaltownsville.myfortressuat.com/api'}
    // public static getAuthURL():String{return 'https://fortressauth.myfortressuat.com/api'}
    // public static getStaffPortalURL():string{return 'https://staffportal.myfortressuat.com/'}
    // public static getAuthDomain():string{return '.myfortressuat.com'}
    // public static getStaffApiURL():String{return 'https://staffportaltownsville.myfortressuat.com/api'}
    // public static getFactFinderURL():string{return 'https://facfinderportal.myfortressuat.com'}
    // public static getFamilyTreeURL():string{return 'https://familytreeportal.myfortressuat.com'}
    // public static getFamilyTreeApiURL():String{return 'https://facfamilytreetownsvile.myfortressuat.com/api'}
    // public static getClientAuthURL():String{return 'https://clientauth.myfortressuat.com/api'}
    // public static getNetwortHistoryURL():string{return 'https://networthhistory.myfortressuat.com/api'}
    // public static getMoneysoftURL():string{return 'https://moneysoftportal.myfortressuat.com'}



    // ===== Grafton client portal ===========================================================================================

//     public static getLogoURL():string{return 'assets/img/logo.png'}
//     public static getLogoNameURL():string{return 'assets/img/logo_name.png'}
//     public static getBusinessName():string{return 'My Fortress Grafton'}

//    // live
//     public static getBaseURL():String{return 'https://api.grafton.myfortress.com.au/api'}
//     public static getAuthURL():String{return 'https://api.auth.myfortress.com.au/api'}
//     public static getStaffPortalURL():string{return 'https://staffportal.grafton.myfortress.com.au'}
//     public static getAuthDomain():string{return '.myfortress.com.au'}
//     public static getStaffApiURL():String{return 'https://api.grafton.staff.myfortress.com.au/api'}
//     public static getFactFinderURL():string{return 'https://facfinder.grafton.myfortress.com.au'}
//     public static getFamilyTreeURL():string{return 'https://familytree.grafton.myfortress.com.au'}
//     public static getFamilyTreeApiURL():String{return 'https://api.grafton.facfamilytree.myfortress.com.au/api'}
//     public static getClientAuthURL():String{return 'https://api.clientauth.myfortress.com.au/api'}
//     public static getNetwortHistoryURL():string{return 'https://api.networthhistory.myfortress.com.au/api'}
//     public static getMoneysoftURL():string{return 'https://moneysoft.grafton.myfortress.com.au'}

//     public static assetType: string = 'Asset';
//     public static liabilityType: string = 'Liability';
//     public static unclassifiedCategory: string = 'UnClassified';
//     public static unclassifiedSubCategory: string =' Unclassified';
 }
