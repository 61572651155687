export class ServiceAgreementModel {
    public  fdsServiceAgreementAddModel : fdsServiceAgreementAddModel;
    public  fdsServiceDocuments : fdsServiceDocuments[];
    public  fdsRecipients : fdsRecipients[];
}

export class ServiceAgreementAddModel extends ServiceAgreementModel{    
    envelopeID: string;
    id: string;
    ValidationMessage: any;
    returnURL: string;
}


export class fdsServiceAgreementAddModel {
    public xeppoCode: number;
    public xeppoGroupCode: number;
    public expireDate: any;
    public clientCode: string;
    public title: string;
    public description: string;
    public fdsAgreementStatus: number;
    public isDocVisibleToClient: boolean;
    public advisorName: string;
    public advisorEmail: string;
    public isAdvisor: string;
    public isSignByRec: boolean;
}

export class fdsServiceAgreementUpdateModel {
    public expireDate: any;
    public title: string;
    public description: string;
    public fdsAgreementStatus: number;
    public isDocVisibleToClient: boolean;
}

export class fdsServiceDocuments {
    public documentName: string;
    public templateName: string;
    public templateId: string;
    public s3Link: string;
    public fileSize: any;
}

export class fdsServiceOldDocuments extends fdsServiceDocuments{
    public id: string;
    public isArchived: boolean;
    public createdDate: any;
    public updatedDate: any;
}

export class fdsRecipients {
    public xeppoCode: string;
    public xeppoGroupCode: string;
    public clientCode: string;
    public userId: string;
    public sendDate: string;
    public signedDate: string;
    public email: string;
    public isMobileUser: boolean;
    public isSigned: boolean;
    public name: string;
    public advisorEmail: string;
    public advisorName: string;
}

export class fdsOldRecipients extends fdsRecipients {
    public id: string;
    public isArchived: boolean;
    public createdDate: any;
    public updatedDate: any;
    
    public issignRec: string; //identify recipient is sign or not. this one get for realtime from envilop
}

export class fdsServiceDocumentsAdd {
    public docInBase64: string;
    public documentName: string;
    public templateName: string;
    public templateId: string;
    public s3Link: string;
    public fileSize: any;
    public document : File;
}

export class ServiceAgreementListModel {  
    
    public  id: string;
    public  envelopeID: string;
    public  expireDate: string;
    public  createdDate: string;
    public  updatedDate: string;
    public  title: string;
    public  description: string;
    public  fdsAgreementStatus: number;
    public  isDocVisibleToClient: boolean;
    public  xeppoCode: string;
    public  clientCode: string;
    public  xeppoGroupCode: string;
    public  fdsServiceDocuments : fdsServiceDocuments[];
    public  fdsRecipients : fdsRecipients[];
}

export class userGroupModel {
    public xeppoCode: string;
    public id: string;
    public clientCode: string;
    public email: string;
    public isMobileUser: boolean;
    public userType: boolean;
    public  userName: string;
}